<template>
  <div class="main-view">
    <div class="tableType">
      <el-radio-group v-model="table.params.status" @change="handleTabs">
        <el-radio-button :label="1">待审核{{table.audit_total}}</el-radio-button>
        <el-radio-button :label="2">已通过{{table.sign_total}}</el-radio-button>
        <el-radio-button :label="3">待签署{{table.init_total}}</el-radio-button>
      </el-radio-group>
    </div>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_name">
          <el-input clearable v-model="table.params.company_name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input clearable v-model="table.params.phone" placeholder="请输入"></el-input>
        </el-form-item>
        <!--  只有总公司账号才有权限选择开票公司 -->
        <el-form-item v-if="this.icId === 0" label="开票单位" prop="invoice_company_id">
          <el-select v-model="table.params.invoice_company_id" placeholder="请选择" clearable filterable>
            <el-option
              v-for="item in invoiceCompanyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="sign_status" v-if="table.params.status == 2">
          <el-select v-model="table.params.sign_status">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="有效" :value="1"></el-option>
            <el-option label="即将失效" :value="2"></el-option>
            <el-option label="已失效" :value="3"></el-option>
            <el-option label="待续签" :value="4"></el-option>
            <el-option label="已续签" :value="5"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="curField"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
     
      <template v-slot:contract_type="{row}">
        <span>{{toStr({1:'劳动合同',2:'劳务合同',3:'实习协议',4:'兼职协议'},row.contract_type)}}</span>
      </template>
      <template v-slot:contract_date_type="{row}">
        <span>{{toStr({0: '', 1:'固定期限',2:'无固定期限',3:'完成一定工作量'},row.contract_date_type)}}</span>
      </template>
      <template v-slot:sign_status="{row}">
        <span>{{toStr({0: '无', 1:'有效',2:'即将失效',3:'已失效',4:'待续签',5:'已续签'},row.sign_status)}}</span>
      </template>
      <template v-slot:contract_day_start="{row}">
        <span>{{row.contract_day_start}} - {{row.contract_day_end}}</span>
      </template>
      <template v-slot:sign_image_url="{row}">
        <div class="tableImg" v-if="!!row.sign_image_url">
          <el-image
            style="width: 60px; height: 60px;transform: scale(1) rotate(-90deg);"
            :src="`https://qn.cdn.jszhr.com/${row.sign_image_url}`"
            fit="cover"
            :modal-append-to-body="true"
            @click="showImg(row)"
            ></el-image>
          <!-- <el-image
            style="width: 60px; height: 60px;transform: scale(1) rotate(-90deg);"
            :src="`https://qn.cdn.jszhr.com/${row.sign_image_url}`"
            fit="cover"
            :modal-append-to-body="true"
            :preview-src-list="[`https://qn.cdn.jszhr.com/${row.sign_image_url}`]"></el-image> -->
        </div>
        <span v-else></span>
      </template>
      <template v-slot:name="{row}">
        <el-button type="text" @click="showDetail(row)">{{row.name}}</el-button>
      </template>
      <template v-slot:action="{row}">
        <div v-show="table.params.status == 1">
          <el-button type="text" @click="handleLook(row,1)">预览</el-button>
          <el-button type="text" @click="setConfirm('确认通过吗?',{id:row.id,audit:1}, '/admin/contract/audit')">通过</el-button>
          <el-button type="text" @click="setConfirm('确认拒绝吗?',{id:row.id,audit:2}, '/admin/contract/audit')">拒绝</el-button>
        </div>
        <div v-show="table.params.status == 2">
          <el-button type="text" @click="handleLook(row,2)">查看合同</el-button>
          <!-- sign_status 签署状态：0-无；1-有效；2-即将失效；3-已失效；4-待续签;5-已续签； -->
          <!-- 即将失效 或 已失效 可以续签-->
          <el-button v-if="row.sign_status == 2 || row.sign_status == 3" type="text" @click="handleRenew(row)">续签</el-button>
          <!-- 待续签状态 可以编辑 -->
          <el-button v-if="row.sign_status == 4" type="text" @click="handleRenew(row)">编辑</el-button>
        </div>
      </template>
    </VTable>

    <Detail ref="detail"></Detail>


    <!-- 编辑合同、续签合同 -->
    <el-dialog
      :title="this.form.data.sign_status == 4 ? '编辑合同' : '续签合同'"
      :visible.sync="dialogVisible"
      width="500px"
      :before-close="handleClose">
      <el-form
        ref='elFormDom'
        label-width="120px"
        :model="form.data"
        :rules='form.rules'
        :size="subUnitSize"
        >
        <el-form-item label="员工姓名">
          <div>{{curRow.name}}</div>
        </el-form-item>
        <el-form-item label="所在企业">
          <div>{{curRow.company_name}}</div>
        </el-form-item>
        <el-form-item label="入职日期">
          <div>{{curRow.entry_at}}</div>
        </el-form-item>
        <el-form-item label="合同期限">
          <div>{{curRow.contract_day_start}} 至 {{curRow.contract_day_end}}</div>
        </el-form-item>
        <el-form-item label="续签合同" prop="contract_type">
          <el-select v-model="form.data.contract_type" placeholder="请选择">
            <el-option label="劳动合同" :value="1"></el-option>
            <el-option label="劳务合同" :value="2"></el-option>
            <el-option label="实习协议" :value="3"></el-option>
            <el-option label="兼职协议" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <!-- 如果是劳动合同就有这个字段，其他合同没有 -->
        <template v-if="form.data.contract_type == 1">
          <el-form-item label="期限类型" prop="contract_date_type">
            <el-select v-model="form.data.contract_date_type" placeholder="请选择">
              <el-option label="固定期限" :value="1"></el-option>
              <el-option label="无固定期限" :value="2"></el-option>
              <el-option label="完成一定工作量" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item label="续签期限" prop="contract_day">
          <el-date-picker
            v-model="form.data.contract_day"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工作岗位" prop="company_post_id">
          <el-select v-model="form.data.company_post_id">
            <el-option v-for="item in jobsArr" :label="item.name" :value="item.id" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!-- 只有兼职协议有 -->
        <el-form-item prop='regular_job' label='正式工作单位' v-if="form.data.contract_type == 4">
          <el-input
            v-model.trim='form.data.regular_job'
            clearable
            placeholder='请输入'
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="commit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 图片预览 -->
    <el-image-viewer class="imageBox" v-if="showViewer" :on-close="closeViewer" :url-list="previewImages" />
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Detail from '@/views/staff/components/Detail.vue'
import {getStorage} from '@/storage'
import { mapGetters } from 'vuex'
import { mixinTable } from '@/mixins/table.js'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    VTable,
    Detail,
    ElImageViewer
  },
  computed: {
    ...mapGetters({
      invoiceCompanyOpt: 'invoiceCompany', // 获取开票单位
      companyOpt: 'companyList', // 获取企业列表
    })
  },
  data() {
    return {
      icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司）
      token: getStorage('token'),
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", width:'120', hidden: false},
        { name: "company_name", label: "用工单位", hidden: false},
        { name: "invoice_company_name", label: "开票单位", showTooltip:true, hidden: false},
        { name: "entry_at", label: "入职时间", width:'120', hidden: false},
        { name: "contract_type", label: "合同名称", hidden: false},
        { name: "contract_day_start", label: "合同期限", width:'220', hidden: false},
        { name: "sign_image_url", label: "签名", hidden: false},
        { name: "sign_at", label: "签署时间", hidden: false},
        { name: "created_at", label: "创建时间", hidden: false},
        { name: "action", label: "操作",width: "160", hidden: false }
      ],
      field2: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", width:'120', hidden: false},
        { name: "company_name", label: "用工单位", hidden: false},
        { name: "invoice_company_name", label: "开票单位", showTooltip:true, hidden: false},
        { name: "entry_at", label: "入职时间", width:'120', hidden: false},
        { name: "sign_at", label: "签署时间", hidden: false},
        { name: "contract_type", label: "合同名称", hidden: false},
        { name: "contract_day_start", label: "合同期限", width:'220', hidden: false},
        { name: "audit_at", label: "审核时间", hidden: false},
        { name: "sign_status", label: "状态", hidden: false},
        { name: "action", label: "操作",width: "160", hidden: false }
      ],
      field3: [
        { name: "id", label: "ID", width:"60"},
        { name: "name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", width:'120', hidden: false},
        { name: "company_name", label: "用工单位", hidden: false},
        { name: "invoice_company_name", label: "开票单位", showTooltip:true, hidden: false},
        { name: "entry_at", label: "入职时间", width:'120', hidden: false},
        { name: "created_at", label: "创建时间", hidden: false},
      ],
      table: {
        loading: false,
        params: {
          name: '',
          phone: '',
          company_name: '',
          invoice_company_id: '',
          status: 1, //状态：1-待审核；2-已通过；3-待签署；
          sign_status: 0, //签署状态：0-无；1-有效；2-即将失效；3-已失效；4-已续签；
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
        audit_total: 0, //待审核总数
        sign_total: 0, //已通过总数
        init_total: 0 //待签署总数
      },
      curField: [],

      curRow: {},
      dialogVisible: false,
      form: {
        data: {
          id: '',
          contract_type: '',
          contract_date_type: 1,
          contract_day: '',
          company_post_id: '',
          contract_day_start: '',
          contract_day_end: '',
          regular_job: '',
        },
        rules:{
          contract_type: [{ required: true, message:'必填项', trigger: 'change'}],
          contract_date_type: [{ required: true, message:'必填项', trigger: 'change'}],
          contract_day: [{ required: true, message:'必填项', trigger: 'change'}],
          company_post_id: [{ required: true, message:'必填项', trigger: 'change'}],
          regular_job: [{ required: true, message:'必填项', trigger: 'change'}],
        }
      },
      jobsArr: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < new Date(this.form.data.contract_day_end).getTime() - 8.64e7;
        },
      },
      showViewer: false,
      previewImages: [],
    }
  },
  created() {
    this.curField = this.field;
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/contract/list', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.audit_total = res.data.audit_total;
          this.table.sign_total = res.data.sign_total;
          this.table.init_total = res.data.init_total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleTabs(value) {
      if(value == 1) { // 待审核
        this.curField = this.field;
      } else if(value == 2) { // 已通过
        this.curField = this.field2;
      } else { // 待签署
        this.curField = this.field3;
      }
      this.table.params.page = 1;
      this.getTable();
    },
    // 续签
    handleRenew(row) {
      this.dialogVisible = true;
      this.curRow = row;
      this.getJobs(row.company_id)
      // 默认返显上一次提交的续签信息
      this.form.data.id = row.id; // 合同id
      if(!!row.renew.contract_day_start) {
        this.form.data.company_post_id = row.renew.company_post_id;
        this.form.data.contract_date_type = row.renew.contract_date_type == 0 ? 1 : row.renew.contract_date_type;
        this.form.data.contract_type = row.renew.contract_type;
        this.form.data.regular_job = row.renew.regular_job;
        this.form.data.contract_day = [row.renew.contract_day_start,row.renew.contract_day_end]
      }

      //设置续签日期不能与上一个合同日期重复
      this.pickerOptions = {
        disabledDate(time) {
          return time.getTime() < new Date(row.contract_day_end).getTime() + 8.64e7;
        },
      }
    },
    // 根据企业查询企业的岗位
    getJobs(companyId) {
      this.$http.get('/admin/Company/searchPost', {params:{id:companyId}}).then(res => {
        if(res.code === 1) {
          this.jobsArr = res.data;
        }
      })
    },
    // 续签
    commit() {
      this.$refs.elFormDom.validate(valid => {
        if(valid) {
          let _params = {
            contract_type: this.form.data.contract_type,
            contract_date_type: this.form.data.contract_type == 1 ? this.form.data.contract_date_type : '', // 期限类型只有劳动合同时候才有
            contract_day_start: this.form.data.contract_day[0],
            contract_day_end: this.form.data.contract_day[1],
            company_post_id: this.form.data.company_post_id,
            regular_job: this.form.data.regular_job,
            id: this.form.data.id,
          }
          this.$http.post('/admin/contract/renew',_params).then(res => {
            if(res.code == 1) {
              this.$message.success('操作成功！')
              this.dialogVisible = false;
              this.getTable()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row.employee_id) // 传入员工id
      dom = null
    },
    handleClose() {
      this.dialogVisible = false;
      this.form.data.contract_date_type = 1;
      this.$refs.elFormDom.resetFields();
    },
    // 查看合同
    handleLook(row, type) {
      if(type == 1) { // 预览
        window.open(row.preview_url)
      } else if(type == 2) { // 查看合同
        window.open(row.preview_url)
      }
      // window.open('https://qn.cdn.jszhr.com/labor/file_b803cb19f609e8bed3d9f3cf5934c541.pdf')
    },
   
    // 查看图片
    showImg(row) {
      if (row.sign_image_url) {
        this.showViewer = true;
        this.previewImages = [`https://qn.cdn.jszhr.com/${row.sign_image_url}`];
      } else {
        this.$message({
          type: "error",
          message: "图片不存在!",
        });
      }
    },
    // 关闭图片
    closeViewer() {
      this.showViewer = false;
      this.previewImages = [];
    },
  }
}
</script>
<style >
.imageBox .el-image-viewer__canvas {
  transform: rotate(-90deg);
}
</style>